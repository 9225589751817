<template>
<!-- Wallet Card -->
<div class="section pt-1">
    <div class="wallet-card">
        <!-- Balance -->
        <div class="balance">
            <div class="left">
                <span class="title mal-text">സാധനങ്ങൾ വാങ്ങുന്നതിനുള്ള ഷോപ്പുകൾ</span>
                <!-- <h1 class="total text-primary">
                    {{ member_lists ? member_lists.total : "00" }}
                </h1> -->
                <form class="search-form pb-2">
                    <!-- <label class="label text-primary" style="margin-bottom: 0px" for="stay"><span>റിലീഫ് പദ്ധതി</span></label> -->
                    <div class="form-group searchbox mal-text">
                        <select as="select" class="form-control custom-select" v-model="filter_type" name="filter_type" id="select4" @change="getMembersList(1, $event.target.value, filter_reason)">
                            <option value>---Select---</option>
                            <option v-for="type in relief_type_list" v-bind:value="type.id" v-bind:key="type.id">
                                {{ type.name }}
                            </option>
                        </select>
                        <i class="input-icon">
                            <ion-icon name="filter-outline"></ion-icon>
                        </i>
                    </div>
                </form>
            </div>
            <div class="right">
                <a href="javascript:void(0)" @click="showUpdateForm()" class="button" v-if="this.permissions.update">
                    <ion-icon name="add-outline"></ion-icon>
                </a>
            </div>
        </div>
    </div>
</div>
<!-- <div id="search">
    
</div> -->
<div class="section pt-0 mt-0">
    <div class="listed-detail mt-0">
        <h3 class="text-center mt-0 mal-text custom-title">ഷോപ്പുകളുടെ ലിസ്റ്റ്</h3>
    </div>
</div>
<div class="section text-primary" v-if="permissions.result_download">
    <span><a :href="download" :download="'Result'">
            <ion-icon name="download-outline"></ion-icon> Download
        </a></span>
</div>
<div class="section text-center" v-if="loading_list">
    <div class="spinner-border text-secondary" role="status"></div>
</div>
<div class="listed-detail mt-0">
    <h4 class="text-center text-danger mt-0 mal-text" v-if="!loading_list && member_lists.total <= 0 && filter_type">
        ഇതുവരെ ഒന്നും ചേർത്തിട്ടില്ല
    </h4>
    <h4 class="text-center mt-0 mal-text" v-if="!loading_list && !filter_type">
        ഏതെങ്കിലും റിലീഫ് പദ്ധതി സെലക്ട് ചെയ്യുക.
    </h4>
</div>

<ul class="listview image-listview inset text mt-1" v-if="!loading_list && member_lists.total > 0">
    <li v-for="(member_list, index) in member_lists.data" v-bind:key="member_list.id" :style="{ 'background-color': background }">
        <a href="javascript:void(0)" class="item">
            <span class="sl-no">{{ member_lists.from + index }}</span>
            <div class="avatar-section">
                <a href="#">
                    <img src="assets/img/sample/avatar/store.png" alt="avatar" class="image" />
                    <!-- <span class="button verified" v-if="member_list.member.verified == 'Y'">
                                            <ion-icon name="checkmark-outline"></ion-icon>
                                        </span>
                                        <span class="button not-verified" v-if="member_list.member.verified == 'N' && member_list.member.verification_status">
                                            <ion-icon name="close-outline"></ion-icon>
                                        </span> -->
                </a>
            </div>
            <div class="in">
                <div class="min-width-50">
                    <!-- <header>User</header> -->
                    <b> {{ member_list.shop_name }}</b>
                    <footer class="text-primary">
                        <!-- <span v-if="member_list.parent_name">Parent: {{ member_list.parent_name }} <br> </span> -->
                        <span v-if="member_list.shop_mobile">{{ member_list.shop_mobile }} </span>
                        <!-- <span v-if="member_list.deliveries.length > 0" class="mal-text text-success"><br> കൊടുത്തത്: <span v-for="(given_item,index) in member_list.deliveries" :key="given_item.id">{{given_item.relief_type.name_mal}} {{member_list.deliveries.length == index+1 ? '':','}}</span></span> -->
                    </footer>
                </div>
                <div class="card-button dropdown">
                    <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal" :data-bs-target="
                '#actionSheetInset' + member_list.id + '-' + member_list.child_id
              " @click="showActionModel">
                        <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                    </button>
                </div>

                <!-- Default Action Sheet Inset -->
                <div class="modal fade action-sheet" :id="'actionSheetInset' + member_list.id + '-' + member_list.child_id" tabindex="-1" role="dialog">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Action</h5>
                            </div>
                            <div class="modal-body">
                                <ul class="action-button-list">
                                    <li>
                                        <a :href="'tel:' + member_list.shop_mobile" class="btn btn-list">
                                            <span>
                                                <ion-icon name="call-outline"></ion-icon> Call
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" class="btn btn-list" data-bs-dismiss="modal" @click="showUpdateForm(member_list)">
                                            <span>
                                                <ion-icon name="pencil-outline"></ion-icon> Edit
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" @click="adminDeleteConfirmation(member_list)" class="btn btn-list" data-bs-dismiss="modal">
                                            <span>
                                                <ion-icon name="trash-outline"></ion-icon> Remove
                                            </span>
                                        </a>
                                    </li>
                                    <li class="action-divider"></li>
                                    <li>
                                        <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                            <span>
                                                <ion-icon name="close-outline"></ion-icon> Cancel
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- * Default Action Sheet Inset -->
            </div>
        </a>
    </li>
</ul>
<div class="section mt-2" v-if="!loading_list">
    <vue-awesome-paginate v-if="member_lists.total > 0" :total-items="member_lists.total" :items-per-page="member_lists.per_page" :max-pages-shown="3" v-model="member_lists.current_page" :on-click="getMembersList" :show-ending-buttons="true" :show-breakpoint-buttons="false" />
</div>

<div class="modal fade action-sheet" id="updateStatusModel" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Shop Details</h5>
            </div>
            <Form @submit="handleRegistration" :validation-schema="schema">
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <div class="">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label mal-text" for="stay"><span>റിലീഫ് പദ്ധതി</span>
                                        <span class="text-danger">*</span></label>
                                    <Field as="select"  class="form-control custom-select" v-model="form_relief_type" name="form_relief_type" id="select4">
                                        <option value>---Select---</option>
                                        <option v-for="type in relief_type_list" v-bind:value="type.id" v-bind:key="type.id">
                                            {{ type.name }}
                                        </option>
                                    </Field>
                                    <ErrorMessage name="form_relief_type" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic" v-if="currentUser.user_level <= 2 && this.user_level == 2">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="zone_id">Zone</label>
                                    <select as="select" class="form-control custom-select" v-model="zone_id" name="zone_id" id="select4">
                                        <option value>---Select---</option>
                                        <option v-for="zone in zone_list" v-bind:value="zone.id" v-bind:key="zone.id">{{
                                            zone.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="receipt_number">
                                        ഷോപ്പിന്റെ പേര് അല്ലെങ്കിൽ ഉടമസ്ഥന്റെ പേര്
                                        <span class="text-danger">*</span>
                                    </label>
                                    <Field type="text" class="form-control" name="shop_name" v-model="formData.shop_name" placeholder="Shop Name" autocomplete="off" />
                                    <ErrorMessage name="shop_name" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="receipt_number">
                                        ഷോപ്പിൻ്റെ അല്ലെങ്കിൽ ഉടമസ്ഥന്റെ ഫോൺ നമ്പർ
                                        <span class="text-danger">*</span>
                                    </label>
                                    <Field type="number" class="form-control" name="shop_mobile" v-model="formData.shop_mobile" placeholder="Shop Mobile" autocomplete="off" />
                                    <ErrorMessage name="shop_mobile" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="receipt_number">
                                        ഷോപ്പിന്റെ ഗൂഗിൾ മാപ്പ് ലൊക്കേഷൻ
                                    </label>
                                    <Field type="text" class="form-control" name="shop_google_map" v-model="formData.shop_google_map" placeholder="Shop Google Map" autocomplete="off" />
                                    <ErrorMessage name="shop_google_map" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="receipt_number">
                                        ഷോപ്പിന്റെ ബോർഡ് കാണാവുന്ന രീതിയിൽ ഉള്ള ഒരു ഫോട്ടോ ഇവിടെ അപ്‌ലോഡ്
                                        ചെയ്യുക
                                    </label>
                                    <div class="custom-file-upload" id="fileUpload1">
                                        <input type="file" id="fileuploadInput" @change="uploadImage($event)"/>
                                        <label for="fileuploadInput">
                                            <span>
                                                <strong>
                                                    <ion-icon name="arrow-up-circle-outline"></ion-icon>
                                                    <i>Upload</i>
                                                </strong>
                                            </span>
                                        </label>
                                        <label v-if="profile_image" for="fileuploadInput" class="file-uploaded" :style="{ 'background-image': 'url(' + profile_image + ')' }">
                                        </label>
                                    </div>
                                    <!-- <Field type="file" class="form-control" name="shop_photo" v-model="formData.shop_photo" placeholder="Shop Photo" autocomplete="off" /> -->
                                    <ErrorMessage name="shop_photo" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="receipt_number">
                                        ഷോപ്പിന്റെ അല്ലെങ്കിൽ ഉടമസ്ഥന്റെ ബാങ്ക് അക്കൗണ്ട് നമ്പർ
                                    </label>
                                    <Field type="number" class="form-control" name="shop_bank_account" v-model="formData.shop_bank_account" placeholder="Shop Bank Account" autocomplete="off" />
                                    <ErrorMessage name="shop_bank_account" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="account_holder_name">
                                        Account Holder Name
                                    </label>
                                    <Field type="text" class="form-control" name="account_holder_name" v-model="formData.account_holder_name" placeholder="Account Holder Name" autocomplete="off" />
                                    <ErrorMessage name="account_holder_name" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="bank_name">
                                        Bank Name
                                    </label>
                                    <Field type="text" class="form-control" name="bank_name" v-model="formData.bank_name" placeholder="Bank Name" autocomplete="off" />
                                    <ErrorMessage name="bank_name" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="receipt_number">
                                        IFSC നമ്പർ
                                    </label>
                                    <Field type="text" class="form-control" name="shop_ifse" v-model="formData.shop_ifse" placeholder="Shop Ifse" autocomplete="off" />
                                    <ErrorMessage name="shop_ifse" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="branch">
                                        Branch
                                    </label>
                                    <Field type="text" class="form-control" name="branch" v-model="formData.branch" placeholder="Branch" autocomplete="off" />
                                    <ErrorMessage name="branch" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="receipt_number">
                                        ഷോപ്പിന്റെ ഗൂഗിൾ പേ നമ്പർ
                                    </label>
                                    <Field type="number" class="form-control" name="shop_gpay_number" v-model="formData.shop_gpay_number" placeholder="Shop Gpay Number" autocomplete="off" />
                                    <ErrorMessage name="shop_gpay_number" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="receipt_number">
                                        ഗൂഗിൾ പെയിൽ കൊടുത്ത പേര്
                                    </label>
                                    <Field type="text" class="form-control" name="shop_gpay_name" v-model="formData.shop_gpay_name" placeholder="Shop Gpay Name" autocomplete="off" />
                                    <ErrorMessage name="shop_gpay_name" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="receipt_number">
                                        UPI ID ഉണ്ടെങ്കിൽ അത്
                                    </label>
                                    <Field type="text" class="form-control" name="shop_upi" v-model="formData.shop_upi" placeholder="Shop UPI" autocomplete="off" />
                                    <ErrorMessage name="shop_upi" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic" v-if="form_relief_type != 1113 && form_relief_type !=1116">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="receipt_number">
                                        ഈ ഷോപ്പ് എത്ര വലിയ കിറ്റുകൾ (ഒരു കിറ്റ് 1200 രൂപ) പണം നൽകാതെ സ്പോൺസർ
                                        ചെയ്യും? 
                                    </label>
                                    <Field type="number" class="form-control" name="shop_big_kit_free_count" v-model="formData.shop_big_kit_free_count" placeholder="Shop big kit free count" autocomplete="off" />
                                    <ErrorMessage name="shop_big_kit_free_count" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic" v-if="form_relief_type != 1113 && form_relief_type !=1116">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="receipt_number">
                                        ഈ ഷോപ്പ് എത്ര ചെറിയ കിറ്റുകൾ ( ഒരു കിറ്റ് 800 രൂപ) പണം നൽകാതെ
                                        സ്പോൺസർ ചെയ്യും? 
                                    </label>
                                    <Field type="number" class="form-control" name="shop_small_kit_free_count" v-model="formData.shop_small_kit_free_count" placeholder="Shop small kit free count" autocomplete="off" />
                                    <ErrorMessage name="shop_small_kit_free_count" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic" v-if="form_relief_type == 1113">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="receipt_number">
                                        കരാറാക്കിയ Discount ശതമാനം
                                    </label>
                                    <Field type="number" class="form-control" name="shop_big_kit_free_count" v-model="formData.shop_big_kit_free_count" placeholder="Shop big kit free count" autocomplete="off" />
                                    <ErrorMessage name="shop_big_kit_free_count" class="error-feedback" />
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary btn-block btn-lg col-6" :disabled="loading">
                                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                <span>Save</span>
                            </button>
                            <a href="javascript:void(0)" class="btn btn-secondary btn-lg col-6 cancel-btn" @click="closeModel()" data-bs-dismiss="modal">
                                Cancel
                            </a>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</div>

<alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
<dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params" ref="dialog_model"></dialog-model>
</template>

<script>
import UserService from "../../services/user.service";
import $ from "jquery";
import * as yup from "yup";
import {
    Field,
    Form,
    ErrorMessage
} from "vee-validate";
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
export default {
    name: "Admin List",
    components: {
        Form,
        Field,
        ErrorMessage,
        AlertModel,
        DialogModel,
    },
    props: {
        unit_id: null,
    },
    data() {
        const schema = yup.object().shape({
            shop_name: yup.string().required("ഫിൽ ചെയ്യുക!"),
            shop_mobile: yup.string().required("ഫിൽ ചെയ്യുക!"),
            form_relief_type: yup.string().required("സെലക്റ്റ് ചെയ്യുക!"),
            shop_google_map: yup.string().required("ഫിൽ ചെയ്യുക!"),
            shop_bank_account: yup.string().required("ഫിൽ ചെയ്യുക!"),
            shop_ifse: yup.string().required("ഫിൽ ചെയ്യുക!"),
            shop_gpay_number: yup.string().required("ഫിൽ ചെയ്യുക!"),
            shop_gpay_name: yup.string().required("ഫിൽ ചെയ്യുക!"),
            shop_upi: yup.string().required("ഫിൽ ചെയ്യുക!"),
        });
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: "91",
            country_code_whatsapp: "91",
            mobile_number: "",
            whatsapp_number: "",
            wing: "",
            schema,
            stay: "",
            user_level: "",
            sub_levels: [],
            member_lists: [],
            member_list: [],
            member: [],
            member_edit: true,
            orgnal_member_lists: [],
            trash_list: [],
            unit: [],
            editable_id: "",
            editable: false,
            permissions: [],
            add_admin: false,
            abroad_fields: false,
            pin_number: "",
            attendance: "A",
            attendance_remarks: "",
            search_qty: "",
            member_id: "",
            alert_type: "",
            alert_message: "",
            dialog_message: "",
            dialog_action: "",
            dialog_action_params: [],
            attendance_present: [],
            attendance_absent: [],
            filter_type: "",
            isMember: "Y",
            isSubscriber: "",
            receipt_no: "",
            subscription_no: "",
            isEdit: false,
            sub_level_name: "",
            download: "",
            relief_reason_list: [],
            relief_type_list: [],
            selected_relief_type : [],
            filter_reason: "",
            form_relief_type: "",
            selected_relief_shop: [],
            relief_deliveries: [],
            formData: [],
            profile_image: null,
            profile_file: null,
            profile_image_save: false,
            profile_image_uploaded: false,
            zone_list:[],
            zone_id : ''
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        minusToZero() {
            let value = this.unit.nerpatham_target - this.member_lists.total;
            if (value <= 0) {
                return "0";
            } else {
                return value;
            }
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace("/");
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            this.getMembersList(1);
            if(this.user_level == 2){
                this.getZoneList(this.$route.params.id);
            }
            this.$root.updateParent("Loading...");
        }
        this.getComboboxValues("RELIEF_TYPE");
        window.onpopstate = function () {
            if ($("body").hasClass("modal-open")) {
                location.reload();
            }
        };
    },
    methods: {
        uploadImage(event) {
            var file = event.target.files[0];
            this.profile_file = file;
            this.profile_image = URL.createObjectURL(file);
            this.profile_image_save = true;
        },
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id,
                special_type: 'relief_type'
            };
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case "RELIEF_TYPE":
                            response.data.data.list.options.forEach(element => {
                                if(element.item_value_display == 'shop'){
                                    this.relief_type_list.push(element);
                                }
                            });
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getTextList(event, type) {
            this.message = "";
            let data = {
                q: event,
                type: type,
                filter: "inside",
                unit_id: this.$route.params.id,
                user_level: this.user_level,
            };
            UserService.authPostRequest("get-typehead-values", data).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.member_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },

        getMembersList(page = 1, filter_type = null) {
            this.loading_list = true;
            let data = {
                user_level: this.user_level,
                unit_id: parseInt(this.$route.params.id),
                filter_type: filter_type ? filter_type : this.filter_type,
            };
            UserService.authPostRequest(`get-relief-shopes?page=${page}`, data).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.permissions = response.data.data.permissions;
                        this.download = response.data.data.result_download_url;
                        this.member_lists = response.data.data.list;
                        this.search_qty = "";
                        this.orgnal_member_lists = response.data.data.list.data;
                        this.unit = response.data.data.unit;
                        this.loading_list = false;
                        this.$root.updateParent("വിസ്‌ഡം റിലീഫ് ലിസ്റ്റ്");
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminDeleteConfirmation(admin_list) {
            var data = {
                id: admin_list.id,
            };
            this.dialog_action_params = data;
            this.dialog_message = "ശരിക്കും ഡിലീറ്റ് ചെയ്യണോ?";
            this.dialog_action = "adminDelete";
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        adminDelete(data) {
            UserService.authPostRequest("delete-relief-shope", data).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.alert_type = "Success";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(1);
                        this.loading = false;
                    } else if (response.data.status == "warning") {
                        this.alert_type = "Warning";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = "Error";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminEdit(member_list) {
            this.$router.push(
                "/org/" +
                this.$route.params.sub_level +
                "/" +
                this.$route.params.id +
                "/relief-entry/" +
                member_list.id
            );
        },
        showUpdateForm(member_list = null) {
            this.selected_relief_shop = member_list;
            this.editable_id = member_list ? member_list.id : null;
            if (member_list) {
                this.zone_id = member_list.zone_id;
                this.formData.shop_name = member_list.shop_name;
                this.formData.shop_mobile = member_list.shop_mobile;
                this.form_relief_type = member_list.relief_type_id;
                this.formData.shop_google_map = member_list.shop_google_map;
                this.formData.shop_bank_account = member_list.shop_bank_account;
                this.formData.shop_ifse = member_list.shop_ifse;
                this.formData.shop_gpay_number = member_list.shop_gpay_number;
                this.formData.shop_gpay_name = member_list.shop_gpay_name;
                this.formData.shop_upi = member_list.shop_upi;
                this.formData.shop_big_kit_free_count = member_list.shop_big_kit_free_count;
                this.formData.shop_small_kit_free_count = member_list.shop_small_kit_free_count;
                if (member_list.shop_photo) {
                    this.profile_image = 'https://guide.msoftit.com/' + member_list.shop_photo;
                }

            } else {
                this.formData.shop_name = '';
                this.formData.shop_mobile = '';
                this.form_relief_type = this.filter_type;
                this.formData.shop_google_map = '';
                this.formData.shop_bank_account = '';
                this.formData.shop_ifse = '';
                this.formData.shop_gpay_number = '';
                this.formData.shop_gpay_name = '';
                this.formData.shop_upi = '';
                this.formData.shop_big_kit_free_count = 0;
                this.formData.shop_small_kit_free_count = 0;

            }

            $("#updateStatusModel").modal("show");
        },
        closeModel() {
            $("#updateStatusModel").modal("hide");
        },
        handleRegistration(data) {
            this.loading = true;
            const formData_ = new FormData();
            if (this.profile_file) {
                formData_.append('shop_photo', this.profile_file);
            }
            formData_.append('data', data);
            if(this.editable_id){
                formData_.append('editable_id', this.editable_id);
            }
            formData_.append('user_level', parseInt(this.$route.params.sub_level));
            formData_.append('unit_id', parseInt(this.$route.params.id));
            formData_.append('zone_id', parseInt(this.zone_id));
            formData_.append('shop_name', this.formData.shop_name);
            formData_.append('shop_mobile', this.formData.shop_mobile);
            formData_.append('form_relief_type', this.form_relief_type);
            formData_.append('shop_google_map', this.formData.shop_google_map);
            formData_.append('shop_bank_account', this.formData.shop_bank_account);
            formData_.append('shop_ifse', this.formData.shop_ifse);
            formData_.append('shop_gpay_number', this.formData.shop_gpay_number);
            formData_.append('shop_gpay_name', this.formData.shop_gpay_name);
            formData_.append('shop_upi', this.formData.shop_upi);
            formData_.append('shop_big_kit_free_count', this.formData.shop_big_kit_free_count);
            formData_.append('shop_small_kit_free_count', this.formData.shop_small_kit_free_count);

            UserService.authPostRequest("store-relief-shope", formData_).then(
                (response) => {
                    if (response.data.status == "success") {
                        $("#updateStatusModel").modal("hide");
                        this.alert_type = "Success";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(1, this.filter_type);
                        this.loading = false;
                    } else if (response.data.status == "warning") {
                        this.alert_type = "Warning";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = "Error";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getZoneList(district_id) {
            let data = {
                district_id: district_id
            }
            UserService.authPostRequest(`get-zone-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.zone_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    },
};
</script>

<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.wallet-card {
    padding-bottom: 0px !important;
}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.wallet-card .balance .title {
    color: #27173e;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}

.cancel-btn {
    left: 4% !important;
}
</style>
