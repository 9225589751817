<template>
    <div id="search" class="mt-1">
        <form class="search-form">
            <div class="row">
                <div class="input-group form-group" style="width:70% !important;">
                    <span class="input-group-text" id="basic-addon3">Mobile</span>
                    <input type="number" class="form-control" maxlength="9999" focus v-model="search_qry"
                        aria-describedby="basic-addon3" style="border: 1px solid #1a418c;
                                border-left: 0px;" />
                </div>
                <div class="form-group" style="width:30% !important;">
                    <button type="button" @click="getMembersList()" class="btn btn-primary btn-block btn-md" :disabled="loading">
                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                        <span>Search</span></button>
                </div>
            </div>
        </form>
    </div>
    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-primary" role="status"></div>
    </div>
    <div class="section inset mt-1" v-if="!loading_list &&  member_lists.total == 0" style="height: 400px;">
        <div class="wide-block pt-2 pb-2">
            <div class="listed-detail mt-0 text-center">
                <h3 class="text-center text-primary">No Result</h3>
            </div>
        </div>
    </div>
    <ul class="listview image-listview inset text mt-2" v-if="!loading_list && member_lists.total > 0">
        <li v-for="(member_list, index) in member_lists.data" v-bind:key="member_list.id"
            :style="{ 'background-color': background }">
            <a href="javascript:void(0)" class="item">
                <span class="sl-no">{{ (member_lists.from + index) }}</span>
                <div class="avatar-section">
                    <a href="#">
                        <img src="assets/img/sample/avatar/avatar1.png" alt="avatar" class="image">
                        <span class="button verified" v-if="member_list.payment_status == 'Paid' && permissions.fees">
                            <ion-icon name="checkmark-outline"></ion-icon>
                        </span>
                        <span class="button not-verified"
                            v-if="member_list.payment_status == 'Pending' && permissions.fees">
                            <ion-icon name="close-outline"></ion-icon>
                        </span>
                    </a>
                </div>
                <div class="in">
                    <div class="min-width-50">
                        <!-- <header>User</header> -->
                        <b> {{ member_list.name }} </b>
                        <footer class="text-primary">{{ member_list.mobile }}
                        </footer>
                    </div>
                    <div class="card-button dropdown">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset' + member_list.id" @click="showActionModel">
                            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                        </button>
                    </div>

                    <!-- Default Action Sheet Inset -->
                    <div class="modal fade action-sheet" :id="'actionSheetInset' + member_list.id" tabindex="-1"
                        role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">

                                        <li>
                                            <a :href="'tel:' + member_list.mobile" class="btn btn-list">
                                                <span>
                                                    <ion-icon name="call-outline"></ion-icon> Call
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="permissions.fees">
                                            <a href="javascript:void(0)" @click="adminPayment(member_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="card-outline"></ion-icon> Payment
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="member_list.receipt_link">
                                            <a :href="member_list.receipt_link" download
                                                class="btn btn-list">
                                                <span>
                                                    <ion-icon name="receipt-outline"></ion-icon> Receipt Download
                                                </span>
                                            </a>
                                        </li>
                                        <!-- <li>
                                            <a href="javascript:void(0)" @click="adminDeleteConfirmation(member_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="trash-outline"></ion-icon> Remove
                                                </span>
                                            </a>
                                        </li> -->
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- * Default Action Sheet Inset -->
                </div>
            </a>
        </li>
    </ul>
    <!-- attendanceModel -->
    <div class="modal fade action-sheet" id="addPaymentModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Payment</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="stay"><span>Amount</span></label>
                                <input name="payment_amount" :disabled="true" type="text" class="form-control"
                                    v-model="payment_amount" placeholder="Amount" autocomplete="off" />
                            </div>
                        </div>
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="stay"><span>Payment Status</span></label>
                                <select as="select" class="form-control custom-select" v-model="payment_status"
                                    name="payment_status" id="select4">
                                    <option value>---Status---</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Paid">Paid</option>
                                </select>
                            </div>
                            <input type="hidden" v-model="member_id" />
                        </div>

                        <div class="form-group basic">
                            <button type="button" @click="savePayment()" class="btn btn-primary btn-block btn-lg"
                                :disabled="loading">
                                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                <span>Save</span></button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>
<script>
import $ from 'jquery';
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
import UserService from "../../services/user.service";
export default {
    name: 'Admin List',
    props: {
        unit_id: null
    },
    components: {
        AlertModel,
        DialogModel
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            stay: '',
            user_level: "",
            sub_levels: [],
            member_lists: [],
            unit: [],
            editable_id: '',
            editable: true,
            add_admin: false,
            abroad_fields: false,
            pin_number: '',
            arrival_confirm: '',
            arrival_time: '',
            saturday_coming: '',
            arrival_mode: '',
            spouse_coming: '',
            food_pref: '',
            child_coming: [],
            remarks: '',
            search_qry: '',
            deligate: {
                deligate: [],
                child: [],
                permissions: []
            },
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            payment_amount: '',
            payment_status: '',
            member_id:''
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        }
        this.$root.updateParent('Exam Participant Verification');
        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },

    methods: {
        getMembersList(page = 1) {
            this.loading_list = true;
            let data = {
                event_type: this.$route.params.exam,
                mobile: this.search_qry
            }
            UserService.authPostRequest(`get-qhls-registrations?page=${page}`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.payment_amount = response.data.data.fees_amount;
                        this.permissions = response.data.data.permissions;
                        this.member_lists = response.data.data.list;
                        this.search_qty = '';
                        this.orgnal_member_lists = response.data.data.list.data;
                        this.unit = response.data.data.unit;
                        this.$root.updateParent(this.$route.params.exam + ' Exam Participant Verification');
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminPayment(member) {
            this.member_id = member.id;
            this.payment_status = member.payment_status;
            // this.payment_amount = member.payment_amount;
            $('#addPaymentModel').modal('show');
        },
        savePayment() {
            let data = {
                id: this.member_id,
                payment_status: this.payment_status,
                payment_amount: this.payment_amount
            }
            this.loading = true;
            UserService.authPostRequest('save-exam-payment', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(this.member_lists.current_page);
                        $('#addPaymentModel').modal('hide');
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.alert_type = 'Error';
                    this.alert_message = error.data.message;
                    this.$refs.alert_model.openModel();
                }
            );

        },
    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    margin-bottom: 20px;
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.searchbox .form-control {
    box-shadow: none;
    border-radius: 10px;
    border: 1px solid #bdbdd5;
    height: 36px;
    padding: 0 10px 0 10px;
}

.form-check {
    padding: 0;
    margin: 0;
    padding-left: 10px;
    min-height: auto;
    height: auto;
    padding-bottom: 5px;
}



.input-group .input-group-text {
    background: transparent;
    border: 0;
    border: 1px solid #1a418c;
    /* border-top: 1px solid #1a418c; */
    border-radius: 5px 5px 5px 5px;
    font-weight: 400;
    color: #27173E;
    height: auto;
    width: auto;
    padding: 0px 10px 0px 10px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}


.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}
</style>