<template>
  <div class="section text-center mt-1" v-if="loading">
    <div class="spinner-border text-primary" role="status"></div>
  </div>
  <div class="section pt-1" v-if="event_details.event.banners.length && !loading">
    <carousel :items-to-show="1">
      <slide v-for="banner in event_details.event.banners" :key="banner" autoplay="1500">
        <div class="card card-slider">
          <img :src="banner.image" class="card-img-top" alt="image">
        </div>
      </slide>
      <template #addons>
        <pagination />
      </template>
    </carousel>
  </div>
  <div class="section mt-1" v-if="!loading">
    <div class="section-title text-primary">{{ event_details.event.name }}</div>
    <a :href="event_details.self_link" v-if="event_details.self_link">
      <button type="button" class="btn btn-lg col-12 mb-1" v-bind:class="event_details.self_color">
        <span>{{ event_details.self_text }}</span>
        <!-- <ion-icon name="chevron-forward-outline"></ion-icon> -->
      </button>
    </a>
    <button type="button" v-if="event_details.self_reg" class="btn btn-lg col-12 mb-1" v-bind:class="event_details.self_color" @click="addAttendanceModelShow">
      <span>{{event_details.self_text}}</span>
    </button>
    <router-link v-for="common_button in event_details.common_buttons" v-bind:key="common_button.slug"
      :to="common_button.url">
      <button type="button" class="btn btn-lg col-12 mb-1" v-bind:class="common_button.class">
        <span>{{ common_button.name }}</span>
        <!-- <ion-icon name="chevron-forward-outline" style="font-size: 60px;"></ion-icon> -->
      </button>
    </router-link>
  </div>
  <div class="section mt-1" v-if="!loading && event_details.admin_buttons.length">
    <div class="section-title text-primary" style="margin-bottom: -10px;">Admin Level : {{ currentAdminLevel == 7 ?
    admin_levels['campus'] : admin_levels['org'] }}
      <div class="card-button dropdown"
        v-if="currentUser.campus_user_level == 1 && currentUser.campus_id && currentUser.user_level <= 4 && admin_levels['campus']">
        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
          data-bs-target="#actionSheetInsetChange">
          <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
        </button>
      </div>
    </div>
    <div class="section-title text-primary">
      <!-- <span>Options</span> -->
      <div class="modal fade action-sheet" id="actionSheetInsetChange" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Action</h5>
            </div>
            <div class="modal-body">
              <ul class="action-button-list">
                <li>
                  <a href="javascript:void(0)" class="btn btn-list" data-bs-dismiss="modal" @click="changeAdminLevel()">
                    <span>
                      <ion-icon name="repeat-outline"></ion-icon>
                      Switch to {{ currentAdminLevel == 7 ? admin_levels['org'] : 'Campus' }} Admin
                    </span>
                  </a>
                </li>
                <li class="action-divider"></li>
                <li>
                  <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                    <span>
                      <ion-icon name="close-outline"></ion-icon> Cancel
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-link v-for="admin_button in event_details.admin_buttons" v-bind:key="admin_button.slug"
      :to="admin_button.url">
      <button type="button" class="btn btn-lg col-12 mb-1" v-bind:class="admin_button.class">
        <span> {{ admin_button.name }}</span>
        <!-- <ion-icon name="chevron-forward-outline" style="font-size: 60px;"></ion-icon> -->
      </button>
    </router-link>
  </div>
  <!-- VolunteerModel -->
  <div class="modal fade action-sheet" id="volunteerModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Volunteer Settings</h5>
        </div>
        <div class="modal-body">
          <div class="action-sheet-content">
            <Form @submit="updateVolunteer" :validation-schema="schema_volunteerr">
              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="stay"><span>വളണ്ടിയർ ആകാൻ താല്പര്യമുണ്ടോ?</span></label>
                  <select as="select" class="form-control custom-select" v-model="volanteer_ready"
                    name="volanteer_ready" id="select4">
                    <option value="Y">അതെ</option>
                    <option value="N">അല്ല</option>
                  </select>
                </div>
              </div>
              <div class="form-group basic" v-if="volanteer_ready == 'Y'">
                <div class="input-wrapper">
                  <label class="label" for="stay"><span>വളണ്ടിയർ ആകാൻ താല്പര്യമുള്ള വകുപ്പ്
                      തിരഞ്ഞെടുക്കുക.</span></label>
                  <select as="select" class="form-control custom-select" v-model="volanteer_department"
                    name="volanteer_department" id="select4">
                    <option value="Y">അതെ</option>
                    <option value="N">അല്ല</option>
                  </select>
                </div>
              </div>
              <div class="form-group basic">
                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="btn_loading"
                  v-if="permissions.volunteer_update">
                  <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                  <span>Save</span></button>
                <button type="button" class="btn btn-primary btn-block btn-lg" v-if="!permissions.volunteer_update"
                  @click="closeModal('volunteerModal')">Close</button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade action-sheet" id="addAttendanceModel" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Self Registration</h5>
        </div>
        <div class="modal-body">
          <div class="action-sheet-content">
            <form>
              <div class="text-center">
                <p class="mal-text text-primary">താങ്കളുടെ നമ്പറിന് കീഴിലുള്ള മെമ്പർമാരുടെ പേര് ടിക്ക് ചെയ്തു രജിസ്റ്റർ ചെയ്യുക.</p>
              </div>
              <div class="form-check mb-1" v-for="member in this.members" v-bind:key="member.id">
                <input type="checkbox" checked class="form-check-input" :id="'customCheckb' + member.id"
                  v-bind:value="member.id" v-model="members_attendance">
                <label class="form-check-label" :for="'customCheckb' + member.id">{{
                  member.name
                }}</label>
              </div>
              <div class="form-group basic">
                <button type="button" class="btn btn-primary btn-lg col-6" :disabled="loading"
                  @click="selfRegistration">
                  <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                  <span>Register</span></button>
                <button type="button" class="btn btn-secondary btn-lg col-6" @click="closeSelfModel">Close</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- DeleteMemberModel -->
  <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
  <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
    ref="dialog_model"></dialog-model>
</template>
<script>
import $ from "jquery";
import UserService from "../../../services/user.service";
import AlertModel from "./../../layouts/AlertModel.vue";
import DialogModel from "./../../layouts/DialogModel.vue";
export default {
  name: "Admin List",
  props: {
    unit_id: null,
  },
  components: {
    AlertModel,
    DialogModel,
  },
  data() {
    return {
      alert_type: "",
      alert_message: "",
      dialog_message: "",
      dialog_action: "",
      dialog_action_params: [],
      event_id : '',
      members_attendance:[],
      loading: false,
      btn_loading: false,

      event_details: {
        event: {
          banners: []
        },
        admin_buttons: [],
        common_buttons: []
      },
      permissions: [],
      admin_levels: [],
      currentAdminLevel: '',
      members: []
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      location.replace("/");
    }
    this.event_id = this.$route.params.event_id;

    this.$root.updateParent(this.event_details.event.name);
    if (this.currentUser.user_level == 5 && this.currentUser.campus_user_level == 1) {
      localStorage.setItem('currentAdminLevel', 7);
    }
    this.currentAdminLevel = localStorage.getItem('currentAdminLevel');
    if (!this.currentAdminLevel) {
      localStorage.setItem('currentAdminLevel', this.currentUser.user_level);
    }
    this.currentAdminLevel = localStorage.getItem('currentAdminLevel');
    this.getEventDetails(this.currentAdminLevel);
    window.onpopstate = function () {
      if ($("body").hasClass("modal-open")) {
        location.reload();
      }
    };
  },

  methods: {
    getEventDetails(admin_level = null) {
      this.loading = true;
      let data = {
        slug: this.$route.params.slug,
        page: 'event_dashboard',
        admin_level: admin_level,
        event_id : this.event_id,
        lajna : true
      };
      UserService.authPostRequest(`get-event-details`, data).then(
        (response) => {
          if (response.data.status == "success") {
            this.event_details = response.data.data.event_details;
            this.permissions = response.data.data.permissions;
            this.admin_levels = response.data.data.admin_levels;
            this.members = response.data.data.members;
            this.members.forEach(element => {
              if (element.event_reg == 1) {
                this.members_attendance.push(element.id);
              }
            });
            this.loading = false;
          } else {
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    openModal(id) {
      $('#' + id).modal('show');
    },
    closeModal(id) {
      $('#' + id).modal('hide');
    },
    updateParkingDetails(action) {
      this.loading = true;
      let data = {
        parking_id: this.selected_parking.id,
        action: action,
        event: this.$route.params.event,
      };
      UserService.authPostRequest(`update-event-parking`, data).then(
        (response) => {
          if (response.data.status == "success") {
            this.responseData = response.data.data;
            this.selected_parking = this.responseData.selected_parking;
            this.alert_type = "Success";
            this.alert_message = response.data.message;
            this.$refs.alert_model.openModel();
            // this.$root.updateParent(this.responseData.event.event_name);
            this.loading = false;
          } else if (response.data.status == "warning") {
            this.alert_type = "Warning";
            this.alert_message = response.data.message;
            this.$refs.alert_model.openModel();
            this.loading = false;
          } else {
            this.alert_type = "Error";
            this.alert_message = response.data.message;
            this.$refs.alert_model.openModel();
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    selectParking(parking) {
      localStorage.setItem("parking_id", parking.id);
      this.parking_select = false;
      this.selected_parking = parking;
      this.alert_type = "Success";
      this.alert_message = "Parking Selected.";
      this.$refs.alert_model.openModel();
    },
    changeParking() {
      localStorage.setItem("parking_id", "");
      this.parking_select = true;
      this.selected_parking = [];
      //   this.alert_type = "Success";
      //   this.alert_message = 'Parking Selected.';
      //   this.$refs.alert_model.openModel();
    },
    changeAdminLevel() {
      if (this.currentAdminLevel == 7) {
        localStorage.setItem('currentAdminLevel', this.currentUser.user_level);
      } else {
        localStorage.setItem('currentAdminLevel', 7);
      }
      this.currentAdminLevel = localStorage.getItem('currentAdminLevel');
      this.getEventDetails(this.currentAdminLevel);
    },
    addAttendanceModelShow() {
      $('#addAttendanceModel').modal('show');
    },
    closeSelfModel(){
      $('#addAttendanceModel').modal('hide');
    },
    selfRegistration() {
      let multiple_members = null;
      let multiple_flag = false;
      if (this.members.length) {
        if (this.members_attendance.length) {
          multiple_members = this.members_attendance;
          multiple_flag = true;
        } else {
          this.alert_type = 'Warning';
          this.alert_message = 'പേരിന്റെ നേരെ ടിക്ക് ചെയ്തു രജിസ്റ്റർ ചെയ്യുക.';
          this.$refs.alert_model.openModel();
          return 0;
        }
      }
      let data = {
        multiple_members: multiple_members,
        multiple_flag: multiple_flag,
        event_type: this.$route.params.slug,
        event_id : this.event_id
      }
      UserService.authPostRequest('self-event-registration', data).then(
        (response) => {
          if (response.data.status == 'success') {
            $('#addAttendanceModel').modal('hide');
            if (!response.data.data.members) {
              this.alert_type = 'Success';
              this.alert_message = response.data.message;
              this.$refs.alert_model.openModel();
              this.members_attendance = [];
            } else {
              this.members = response.data.data.members;
              $('#addAttendanceModel').modal('show');
            }
            this.loading = false;
          } else if (response.data.status == 'warning') {
            this.alert_type = 'Warning';
            this.alert_message = response.data.message;
            this.$refs.alert_model.openModel();
            this.loading = false;
          } else {
            this.alert_type = 'Error';
            this.alert_message = response.data.message;
            this.$refs.alert_model.openModel();
          }
        },
        (error) => {
          this.loading = false;
          this.alert_type = 'Error';
          this.alert_message = error.data.message;
          this.$refs.alert_model.openModel();
        }
      );
    },
  },
};
</script>
<style scoped>
.section-heading {
  padding: 10px 10px 0px 10px;
}

.error-feedback {
  color: red;
}

.listview {
  margin-bottom: 20px;
}

.edit-btn {
  margin-left: 8px;
}

.progress {
  width: 80px;
}

.listview>li footer {
  color: #171617;
}

.min-width-50 {
  min-width: 60% !important;
}

.action-button-list>li {
  min-height: 1px !important;
}

.sl-no {
  font-weight: 900;
  margin-right: 12px;
}

.searchbox .form-control {
  box-shadow: none;
  border-radius: 10px;
  border: 1px solid #bdbdd5;
  height: 36px;
  padding: 0 10px 0 10px;
}

.form-check {
  padding: 0;
  margin: 0;
  padding-left: 10px;
  min-height: auto;
  height: auto;
  padding-bottom: 5px;
}

.input-group .input-group-text {
  background: transparent;
  border: 0;
  border: 1px solid #1a418c;
  /* border-top: 1px solid #1a418c; */
  border-radius: 5px 5px 5px 5px;
  font-weight: 400;
  color: #27173e;
  height: auto;
  width: auto;
  padding: 0px 10px 0px 10px;
}

.stat-box {
  background: #ffffff;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
  border-radius: 10px;
  padding: 10px 10px;
}

.wallet-card {
  background: #ffffff;
  box-shadow: 0 1px 3px 0 rgb(209 26 26 / 9%);
  border-radius: 10px;
  padding: 10px 10px;
  position: relative;
  z-index: 1;
}

.wallet-card .balance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  height: auto;
}

.wallet-card .wallet-footer .item .icon-wrapper {
  width: 100%;
  height: 48px;
  display: inline-flex;
  align-items: left;
  justify-content: left;
  border-radius: 10px;
  color: #fff;
  margin-bottom: 0px;
  padding-left: 10px;
}

.menu-title {
  color: #fff !important;
  font-size: 12px !important;
  padding-left: 5px !important;
  text-align: left !important;
}

.wallet-card .balance .total {
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1em;
  font-size: 24px;
}

.wallet-card .balance .title {
  color: #27173E;
  font-weight: 500;
  font-size: 14px;
  display: block;
  margin-bottom: 0px;
}

.section-heading {
  padding: 0px !important;
  margin-bottom: 5px !important;
}

.section-heading .title {
  font-size: 15px;
}

.section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
