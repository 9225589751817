<template>
    <div class="section text-center mt-1" v-if="loading">
        <div class="spinner-border text-primary" role="status"></div>
    </div>
    <div class="section pt-1" v-if="responseData.page_content.banners.length">
        <carousel :items-to-show="1">
            <slide v-for="banner in responseData.page_content.banners" :key="banner" autoplay="1500">
                <div class="card card-slider">
                    <img :src="banner.image" class="card-img-top" alt="image">
                </div>
            </slide>
            <template #addons>
                <pagination />
            </template>
        </carousel>
    </div>
    <div class="section pt-1" v-if="responseData.page_content.video_link">
        <div class="wallet-card">
            <div class="listed-detail mt-0 page-title">
                <h3 class="text-center mal-text mt-1 custom-title" v-html="responseData.page_content.video_text"></h3>
            </div>
            <iframe class="mt-1" width="100%" height="250"
                :src="'https://www.youtube.com/embed/' + responseData.page_content.video_link"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" controls='0'
                allowfullscreen></iframe>
        </div>
    </div>
    <div class="section pt-1" v-if="responseData.exam_form.permissions.show">
        <div class="wrapper">
            <div class="bill-box" :style="responseData.exam_form.bg_color">
                <div class="img-wrapper" v-bind:class="responseData.exam_form.button_icon_color"
                    v-if="!responseData.exam_form.button_image">
                    <ion-icon :name="responseData.exam_form.button_icon"></ion-icon>
                </div>
                <div class="img-wrapper" v-if="responseData.exam_form.button_image">
                    <img :src="responseData.exam_form.button_image" alt="img" class="image-block imaged w48">
                </div>
                <div class="price" v-bind:class="responseData.exam_form.form_heading_color"
                    v-html="responseData.exam_form.form_heading">
                </div>
                <p v-bind:class="responseData.exam_form.title_color">{{ responseData.exam_form.title }}</p>
                <router-link :to="responseData.exam_form.button_url"
                    v-if="exam_button_enabled || responseData.exam_form.permissions.testing">
                    <a href="javascript:void(0)" class="btn btn-block btn-sm"
                        v-bind:class="responseData.exam_form.button_color">{{ responseData.exam_form.button_text }}</a>
                </router-link>

                <h4 class="text-primary" v-if="start_timer">Exam will start in</h4>
                <button type="button" class="btn btn-outline-success timer" v-if="start_timer">{{ hours }}:{{ minutes
                    }}:{{
                        seconds }}</button>
            </div>
        </div>
    </div>
    <div class="section pt-1" v-if="responseData.form.permissions.new_reg">
        <div class="wrapper">
            <div class="bill-box">
                <div class="img-wrapper" v-bind:class="responseData.form.button_icon_color"
                    v-if="!responseData.form.button_image">
                    <ion-icon :name="responseData.form.button_icon"></ion-icon>
                </div>
                <div class="img-wrapper" v-if="responseData.form.button_image">
                    <img :src="responseData.form.button_image" alt="img" class="image-block imaged w48">
                </div>
                <div class="price" v-bind:class="responseData.form.form_heading_color"
                    v-html="responseData.form.form_heading">
                </div>
                <p v-bind:class="responseData.form.title_color">{{ responseData.form.title }}</p>
                <!-- <a href="javascript:void(0)" @click="openFormModel(responseData.form)" class="btn btn-block btn-sm"
                    v-bind:class="responseData.form.button_color">{{ responseData.form.button_text }}</a> -->
                <!-- <h2 class="card-title">Payments</h2> -->
                <ul class="listview image-listview inset text form_result_list text-left">
                    <li v-for="(member, index) in this.members" v-bind:key="member.id">
                        <a href="javascript:void(0)" class="item">
                            <span class="sl-no">{{ (index + 1) }}</span>
                            &nbsp;&nbsp;
                            <div class="in">
                                <div>
                                    <!-- <header class="mal-text">{{member.id}}</header> -->
                                    <b class="list-item-text">{{ member.name }}</b>
                                    <footer>
                                        <span class="text-primary">
                                            {{ member.mobile ? member.mobile : 'No Number' }}
                                        </span>
                                        &nbsp;
                                        <span v-if="member.event_reg == 1" class="text-success"
                                            v-html="responseData.register_status_text"></span>
                                        <span v-if="member.payment_status_text" class="text-success"
                                            v-html="member.payment_status_text">
                                        </span>
                                    </footer>
                                </div>
                                
                                <div class="form-check mb-1" v-if="member.public == 0">
                                    <input type="checkbox" class="form-check-input" :id="'customCheckb' + member.id"
                                        v-bind:value="member.id" v-model="members_selected"
                                        :disabled="member.event_reg == 1 ? true : false">
                                    <label class="form-check-label" :for="'customCheckb' + member.id">
                                    </label>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
                <Form @submit="modelAction" :validation-schema="schema" v-if="new_reg" class="mt-1">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="name">Name<span
                                            class="text-danger">*</span></label>
                                    <Field type="text" class="form-control" name="name" placeholder="Name"
                                        v-model="name" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="name" class="error-feedback" />
                            </div>
                            <div class="row">
                                <div class="col-5">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="gender">Sex <span
                                                    class="text-danger">*</span></label>
                                            <Field as="select" class="form-control custom-select"
                                                name="gender" id="gender" v-model="gander">
                                                <option value="" selected>Select Sex</option>
                                                <option value="M">Male</option>
                                                <option value="F">Female</option>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="gender" class="error-feedback" />
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="age">Age <span
                                                    class="text-danger">*</span></label>
                                            <Field type="text" class="form-control" name="age"
                                                placeholder="Your Age" v-model="age" />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                        <ErrorMessage name="age" class="error-feedback" />
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="form_permissions.new_reg_mobile">
                                <div class="col-5">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="code">Code</label>
                                            <Field as="select" class="form-control custom-select" id="code"
                                                name="code" v-model="code">
                                                <option value="" :disabled="true" selected>#</option>
                                                <option v-for="code in country_code_list"
                                                    v-bind:key="code.item_value"
                                                    v-bind:value="code.item_value">
                                                    {{ code.name }}</option>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="code" class="error-feedback" />
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="mobile">Mobile <span
                                                    class="text-primary mal-text">(ഉണ്ടെങ്കിൽ)</span></label>
                                            <Field type="text" class="form-control" id="mobile"
                                                name="mobile" placeholder="Mobile" v-model="mobile"
                                                oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                        <ErrorMessage name="mobile" class="error-feedback" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group basic" v-if="form_permissions.new_reg_district">
                                <div class="input-wrapper">
                                    <label class="label" for="district">District</label>
                                    <Field as="select" class="form-control custom-select" id="district"
                                        name="district" v-model="district">
                                        <option value="" :disabled="true" selected>Select District</option>
                                        <option v-for="district in district_list" v-bind:key="district.id"
                                            v-bind:value="district.id">
                                            {{ district.name }}
                                        </option>
                                    </Field>
                                </div>
                                <ErrorMessage name="district" class="error-feedback" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group basic" v-if="new_reg">
                        <button type="submit" class="btn btn-primary btn-sm col-6" :disabled="loading">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            {{ reg_model_button_text }}</button>
                        <button type="button" class="btn btn-secondary btn-cancel btn-sm col-6"
                        @click="new_reg = false">Cancel</button>
                    </div>
                </Form>
                <div class="mal-text text-left forget" v-if="!new_reg && form_permissions.new_reg_link">
                    <a href="javascript:void(0)" @click="new_reg = true">
                        {{ new_reg_text }}
                    </a>
                </div>
                <div class="form-group basic" v-if="!new_reg">
                    <button type="button" class="btn btn-primary btn-sm col-12"
                        :disabled="btn_loading || (!reg_model_button_status && !new_reg)"
                        @click="modelAction">
                        <span v-show="btn_loading" class="spinner-border spinner-border-sm"></span>
                        <span>{{ reg_model_button_text }}</span></button>
                </div>
                <a v-if="this.qvp_ids.length" :href="'https://guide.msoftit.com/payment?user_id=' + currentUser.id + '&qvp_id='+qvp_ids" class="btn btn-block btn-sm btn-danger mt-0">Click for Payment</a>
            </div>
        </div>
    </div>
    
    <div class="section pt-1 mb-1" v-if="!loading">
        <div class="card">
            <div class="card-body">
                <!-- <h2 class="card-title" v-bind:class="responseData.page_content.title_class" v-html="responseData.page_content.title"></h2> -->
                <p v-bind:class="responseData.page_content.description_class"
                    v-html="responseData.page_content.description"></p>
            </div>
        </div>
    </div>
    <!-- qhls registration model -->
    <div class="modal fade action-sheet" id="regFormModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-html="reg_model_heading"></h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <form>
                            <div class="text-center">
                                <p class="mal-text text-primary">{{ reg_model_title }}</p>
                            </div>
                            <ul class="listview image-listview inset text form_result_list">
                                <li v-for="(member, index) in this.members" v-bind:key="member.id">
                                    <a href="javascript:void(0)" class="item">
                                        <span class="sl-no">{{ (index + 1) }}</span>
                                        &nbsp;&nbsp;
                                        <div class="in">
                                            <div>
                                                <!-- <header class="mal-text"></header> -->
                                                <b class="list-item-text">{{ member.name }}</b>
                                                <footer>
                                                    <span class="text-primary">
                                                        {{ member.mobile ? member.mobile : 'No Number' }}
                                                    </span>
                                                    &nbsp;
                                                    <span v-if="member.event_reg == 1" class="text-success"
                                                        v-html="responseData.register_status_text"></span>
                                                    <span v-if="member.payment_status_text" class="text-success"
                                                        v-html="member.payment_status_text">
                                                    </span>
                                                </footer>
                                            </div>
                                            <div class="form-check mb-1" v-if="member.public == 0">
                                                <input type="checkbox" class="form-check-input"
                                                    :id="'customCheckb' + member.id" v-bind:value="member.id"
                                                    v-model="members_selected"
                                                    :disabled="member.event_reg == 1 ? true : false">
                                                <label class="form-check-label" :for="'customCheckb' + member.id">
                                                </label>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                            <!-- <div class="mal-text text-left"
                                v-if="!new_reg && form_permissions.payment && member_payment_text">
                                <p v-html="member_payment_text">
                                </p>
                            </div> -->
                            <Form @submit="modelAction" :validation-schema="schema" v-if="new_reg" class="mt-1">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="form-group basic">
                                            <div class="input-wrapper">
                                                <label class="label" for="name">Name<span
                                                        class="text-danger">*</span></label>
                                                <Field type="text" class="form-control" name="name" placeholder="Name"
                                                    v-model="name" />
                                                <i class="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                            <ErrorMessage name="name" class="error-feedback" />
                                        </div>
                                        <div class="row">
                                            <div class="col-5">
                                                <div class="form-group basic">
                                                    <div class="input-wrapper">
                                                        <label class="label" for="gender">Sex <span
                                                                class="text-danger">*</span></label>
                                                        <Field as="select" class="form-control custom-select"
                                                            name="gender" id="gender" v-model="gander">
                                                            <option value="" selected>Select Sex</option>
                                                            <option value="M">Male</option>
                                                            <option value="F">Female</option>
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="gender" class="error-feedback" />
                                                </div>
                                            </div>
                                            <div class="col-7">
                                                <div class="form-group basic">
                                                    <div class="input-wrapper">
                                                        <label class="label" for="age">Age <span
                                                                class="text-danger">*</span></label>
                                                        <Field type="text" class="form-control" name="age"
                                                            placeholder="Your Age" v-model="age" />
                                                        <i class="clear-input">
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i>
                                                    </div>
                                                    <ErrorMessage name="age" class="error-feedback" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" v-if="form_permissions.new_reg_mobile">
                                            <div class="col-5">
                                                <div class="form-group basic">
                                                    <div class="input-wrapper">
                                                        <label class="label" for="code">Code</label>
                                                        <Field as="select" class="form-control custom-select" id="code"
                                                            name="code" v-model="code">
                                                            <option value="" :disabled="true" selected>#</option>
                                                            <option v-for="code in country_code_list"
                                                                v-bind:key="code.item_value"
                                                                v-bind:value="code.item_value">
                                                                {{ code.name }}</option>
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="code" class="error-feedback" />
                                                </div>
                                            </div>
                                            <div class="col-7">
                                                <div class="form-group basic">
                                                    <div class="input-wrapper">
                                                        <label class="label" for="mobile">Mobile <span
                                                                class="text-primary mal-text">(ഉണ്ടെങ്കിൽ)</span></label>
                                                        <Field type="text" class="form-control" id="mobile"
                                                            name="mobile" placeholder="Mobile" v-model="mobile"
                                                            oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" />
                                                        <i class="clear-input">
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i>
                                                    </div>
                                                    <ErrorMessage name="mobile" class="error-feedback" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group basic" v-if="form_permissions.new_reg_district">
                                            <div class="input-wrapper">
                                                <label class="label" for="district">District</label>
                                                <Field as="select" class="form-control custom-select" id="district"
                                                    name="district" v-model="district">
                                                    <option value="" :disabled="true" selected>Select District</option>
                                                    <option v-for="district in district_list" v-bind:key="district.id"
                                                        v-bind:value="district.id">
                                                        {{ district.name }}
                                                    </option>
                                                </Field>
                                            </div>
                                            <ErrorMessage name="district" class="error-feedback" />
                                        </div>

                                        <!-- <div class="mal-text text-left" v-if="new_reg && form_permissions.payment">
                                            <p v-html="payment_text">
                                            </p>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="form-group basic" v-if="new_reg">
                                    <button type="submit" class="btn btn-primary btn-lg col-6" :disabled="loading">
                                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                        {{ reg_model_button_text }}</button>
                                    <button type="button" class="btn btn-secondary btn-cancel btn-lg col-6"
                                        @click="closeModel">Close</button>
                                </div>
                            </Form>
                            <div class="mal-text text-left forget" v-if="!new_reg && form_permissions.new_reg_link">
                                <a href="javascript:void(0)" @click="new_reg = true">
                                    {{ new_reg_text }}
                                </a>
                            </div>
                            <div class="form-group basic" v-if="!new_reg">
                                <button type="button" class="btn btn-primary btn-lg col-6"
                                    :disabled="btn_loading || (!reg_model_button_status && !new_reg)"
                                    @click="modelAction">
                                    <span v-show="btn_loading" class="spinner-border spinner-border-sm"></span>
                                    <span>{{ reg_model_button_text }}</span></button>
                                <button type="button" class="btn btn-secondary btn-cancel btn-lg col-6"
                                    @click="closeModel">Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import UserService from "../../services/user.service";
// import vSelect from 'vue-select'
import $ from 'jquery';
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
import * as yup from "yup";
export default {
    name: 'Registration',
    components: {
        Form,
        Field,
        ErrorMessage,
        // vSelect,
        AlertModel,
        DialogModel
    },
    props: {
        unit_id: null
    },
    data() {
        const schema = yup.object().shape({
            name: yup.string().required('ഫിൽ ചെയ്യുക!'),
            age: yup.string().required('ഫിൽ ചെയ്യുക!'),
            gender: yup.string().required('സെലക്റ്റ് ചെയ്യുക!'),
        });
        return {
            loading: false,
            btn_loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            schema,
            stay: '',
            user_level: "",
            sub_levels: [],
            member_lists: [],
            member_list: [],
            member: [],
            member_edit: true,
            orgnal_member_lists: [],
            trash_list: [],
            unit: [],
            editable_id: '',
            editable: false,
            permissions: [],
            add_admin: false,
            abroad_fields: false,
            pin_number: '',
            attendance: 'A',
            attendance_remarks: '',
            search_qty: '',
            member_id: '',
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            attendance_present: [],
            attendance_absent: [],
            filter_wing: '',
            isMember: 'Y',
            isSubscriber: '',
            receipt_no: '',
            subscription_no: '',
            isEdit: false,
            sub_level_name: '',

            reg_model_forms: [],
            form_permissions: [],
            reg_model_title: '',
            reg_model_heading: '',
            reg_model_extra_fields: [],
            reg_model_button_text: '',
            reg_model_button_status: true,
            event_type: '',
            responseData: {
                exam_form: {
                    permissions: []
                },
                page_content: {
                    banners: []
                },
                form: {
                    permissions: []
                }
            },
            new_reg: false,
            members: [],
            form_name: '',
            members_selected: [],
            user: [],
            payment_text: '',
            exam_button_enabled: false,
            start_timer: false,
            end_timer: false,
            minutes: 0,
            seconds: 0,
            hours: 0,
            district_list: [],
            district: '',
            qvp_ids : '',

        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/user-login');
        } else {
            this.event_type = this.$route.params.page;
            this.getContent(this.event_type);
            this.$root.updateParent('Loading...');
        }
        this.getComboboxValues('COUNTRY_CODE');
        this.getComboboxValues('DISTRICT_KERALA');

        this.getComboboxValues('DISTRICT');
        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        startTimer(duration, type = null) {
            var timer = duration,
                hours, minutes, seconds;
            let parent = this;
            setInterval(function () {
                hours = parseInt((timer / 60) / 60, 10);
                minutes = parseInt(((timer / 60) % 60), 10);
                seconds = parseInt(timer % 60, 10);

                hours = hours < 10 ? "0" + hours : hours;
                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;

                parent.hours = hours;
                parent.minutes = minutes;
                parent.seconds = seconds;
                if (minutes == 0 && seconds == 0) {
                    if (type == 'start') {
                        parent.start_timer = false;
                        parent.exam_button_enabled = true;
                    } else {
                        parent.end_timer = false;
                        parent.exam_button_enabled = false;
                    }
                }
                if (--timer < 0) {
                    timer = duration;
                }
            }, 1000);
        },
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'COUNTRY_CODE':
                            this.country_code_list = response.data.data.list.options;
                            break;
                        case 'UNVERIFIED_REASON':
                            this.verification_status_list = response.data.data.list.options;
                            break;
                        case "DISTRICT_KERALA":
                            this.district_list = response.data.data.list.options;
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        addAdmin(user) {
            user.editable_id = this.editable_id;
            user.is_member = this.isMember;
            user.isSubscriber = this.isSubscriber;
            if (!this.member && this.isMember == 'Y') {
                this.message = 'ദയവായി മെമ്പറെ ലിസ്റ്റിൽ നിന്നും സെലക്ട് ചെയ്തതിനു ശേഷം Save അമർത്തുക';
            }
            if (this.member) {
                user.member_id = this.member.id;
            }
            user.unit_id = parseInt(this.$route.params.id);
            UserService.addAdmin('store-qhls', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAdminModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(1, 6);
                        this.loading = false;
                        this.message = '';
                        setTimeout(() => {
                                location.reload();
                            }, 2000);
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getContent(page = null) {
            this.loading = true;
            let data = {
                page: page
            }
            UserService.authPostRequest(`get-landing-content`, data).then(
                (response) => {
                    console.log(response.data);
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        console.log(this.responseData);
                        if (this.responseData.exam_form) {
                            let timer = this.responseData.exam_form.starting_time - this.responseData.exam_form.current_time;
                            if (timer > 0) {
                                this.exam_button_enabled = false;
                                this.start_timer = true;
                                this.startTimer(timer, 'start');
                            } else {
                                this.exam_button_enabled = true;
                                this.start_timer = false;
                            }

                            // let timer_ =  this.responseData.exam_form.ending_time - this.responseData.exam_form.current_time;
                            // if(timer_ > 0){
                            //     this.exam_button_enabled = false;
                            //     this.end_timer = true;
                            //     this.startTimer(timer,'end');
                            // }
                        }

                        this.members = response.data.data.members;
                        this.user = response.data.data.user;
                        this.members.forEach(element => {
                            if (element.event_reg == 1) {
                                this.members_selected.push(element.id);
                            }
                            if(element.payment_status != 'Paid' && element.event_reg == 1){
                                this.qvp_ids = this.qvp_ids+','+element.exam_reg_id;
                            }
                        });
                        this.$root.updateParent(this.responseData.page_content.title);
                        this.loading = false;
                        this.openFormModel(this.responseData.form);
                    } else {
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        openFormModel(form) {
            this.new_reg = false;
            this.form_permissions = form.permissions;
            this.new_reg_text = form.new_reg_text ? form.new_reg_text : 'പുതിയ ഒരാളെ രജിസ്റ്റർ ചെയ്യാൻ ഇവിടെ ക്ലിക്ക് ചെയ്യുക.';
            this.payment_text = form.payment_text ? form.payment_text : '';
            this.reg_model_heading = form.form_heading;
            this.reg_model_title = form.form_title;
            this.reg_model_button_text = form.form_button_text;
            this.form_name = form.form_name;
            this.reg_model_button_status = form.form_button_status;
            this.name = '';
            this.country_code = '91';
            this.mobile = '',
                this.district = '';
            this.gender = '';
            this.age = '';
            // $('#regFormModel').modal('show');
            // if (this.responseData.payment_pending) {
            //     this.alert_type = 'Warning';
            //     this.alert_message = this.responseData.payment_pending_text;
            //     this.$refs.alert_model.openModel();
            // }
        },
        closeModel() {
            $('#regFormModel').modal('hide');
        },
        modelAction(reg_data = null) {
            this.btn_loading = true;
            if (this.members.length > 0 && this.members_selected.length == 0 && !this.new_Reg) {
                this.alert_type = 'Warning';
                this.alert_message = 'പേര് ടിക്ക് ചെയ്തതിനു ശേഷം സേവ് ചെയ്യുക.';
                this.$refs.alert_model.openModel();
                this.btn_loading = false;
            } else {
                var data = {
                    new_reg: this.new_reg,
                    user_type: this.user.user_type,
                    form_name: this.form_name,
                    members: this.members_selected,
                    new: {
                        name: reg_data.name,
                        code: reg_data.code,
                        mobile: reg_data.mobile,
                        gender: reg_data.gender,
                        age: reg_data.age,
                        district: reg_data.district
                    }
                };
                UserService.addAdmin('auto-form-submit', data).then(
                    (response) => {
                        if (response.data.status == 'success') {
                            this.openFormModel(this.responseData.form);
                            this.alert_type = 'Success';
                            this.members = response.data.data.members;
                            this.alert_message = response.data.message;
                            this.$refs.alert_model.openModel();
                            this.btn_loading = false;
                            this.message = '';
                            setTimeout(() => {
                                location.reload();
                            }, 2000);
                        } else if (response.data.status == 'warning') {
                            this.alert_type = 'Warning';
                            this.alert_message = response.data.message;
                            this.$refs.alert_model.openModel();
                            this.btn_loading = false;
                        } else if (response.data.status == 'error') {
                            this.alert_type = 'Error';
                            this.alert_message = response.data.message;
                            this.$refs.alert_model.openModel();
                            this.btn_loading = false;
                        } else {
                            this.btn_loading = false;
                        }
                    },
                    (error) => {
                        this.alert_type = 'Error';
                        this.alert_message = (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.$refs.alert_model.openModel();
                        this.btn_loading = false;
                    }
                );
            }
        }
    },
};
</script>

<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.forget {
    font-size: 12px !important;
    text-decoration: underline;
}

.form_result_list {
    margin-left: 0px !important;
    margin-right: 0px !important;
    overflow: hidden;
    border: 0;
    border-radius: 10px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    font-size: 12px !important;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.btn-cancel {
    left: 4% !important;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.wallet-card {
    padding-bottom: 0px !important;
}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.wallet-card .balance .title {
    color: #27173E;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}

.card .card-img-top {
    border-radius: 10px 10px 10px 10px;
}

.form-check .form-check-label {
    cursor: pointer;
    position: relative;
    min-height: 28px;
    padding: 0px 0 0 23px;
    line-height: 0.7em;
    color: #fbfbfb;
    border: 1px solid #1a418c;
}

.image-listview>li .item {
    padding: 5px 0px 5px 17px;
    width: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
}

.timer {
    font-size: 24px;
}
</style>