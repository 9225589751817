<template>
    <!-- <div class="lajna-container"> -->
        <div class="section mt-1">
            <!-- Event Slider Section -->
            <div class="slider-wrapper">
                <EventSlider />
            </div>

            <CategoryFilter />

            <hr>
            <div class="section text-center mt-1" v-if="loading">
                <div class="spinner-border text-primary" role="status"></div>
            </div>
            <!-- No Messages Message -->
            <div v-if="!audioItems || audioItems.length === 0" class="no-audios-message">
                <div class="message-content">
                    <i class="fas fa-envelope-open"></i>
                    <h3>Audio is not Available</h3>
                </div>
            </div>

            <div class="audio-section">
                <div v-if="audioError" class="error-banner">
                    {{ audioError }}
                </div>
                <div class="audio-list">
                    <div v-for="audio in audioItems" :key="audio.id" class="audio-card">
                        <div class="audio-header">
                            <button class="play-btn" @click="handlePlay(audio.id)" :disabled="audio.loading">
                                <template v-if="audio.loading">
                                    <span class="loading-spinner"></span>
                                </template>
                                <template v-else>
                                    {{ currentlyPlaying === audio.id ? '❚❚' : '▶' }}
                                </template>
                            </button>
                            <span class="audio-title">{{ audio.title }}</span>
                        </div>
                        <div class="waveform" @click="seekAudio($event, audio.id)">
                            <div class="wave-container">
                                <div v-for="n in 30" :key="n" class="wave-bar" :class="{
                                    'active': currentlyPlaying === audio.id,
                                    'completed': isBarCompleted(audio.id, n),
                                    'current': isCurrentBar(audio.id, n)
                                }">
                                    <div v-if="isCurrentBar(audio.id, n)" class="wave-progress"
                                        :style="{ width: `${getCurrentBarProgress(audio.id, n)}%` }">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="audio-footer">
                            <span v-if="currentlyPlaying === audio.id" class="current-time">
                                {{ formatTime(currentTimes[audio.id] || 0) }}
                            </span>
                            <span class="duration">
                                {{ audio.loading ? 'Loading...' :
                                    audio.error ? 'Error' :
                                        audio.duration || '0:00' }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- </div> -->
</template>

<script>
import CategoryFilter from '../../components/CategoryFilter.vue';
import EventSlider from '../../components/EventSlider.vue';
import UserService from "../../services/user.service";

export default {
    name: 'AudioPlayer',
    data() {
        return {
            permissions : [],
            loading : false,
            audioItems: [
                // {
                //     id: 1,
                //     title: 'كتاب الصلاة | لفضيلة الشيخ عبدالسلام الشويعر',
                //     src: 'https://guide.msoftit.com/lajna_files/audios/audio_1.mpeg',
                //     loading: false,
                //     error: null
                // },
                // {
                //     id: 2,
                //     title: 'أوقات الصلاة | لفضيلة الشيخ عبدالسلام الشويعر',
                //     src: 'https://guide.msoftit.com/lajna_files/audios/audio_2.mpeg',
                //     loading: false,
                //     error: null
                // },
                // {
                //     id: 3,
                //     title: 'كيف نعرف أوقات الصلاة؟ | لفضيلة الشيخ عبدالسلام الشويعر',
                //     src: 'https://guide.msoftit.com/lajna_files/audios/audio_3.mpeg',
                //     loading: false,
                //     error: null
                // },
            ],
            currentlyPlaying: null,
            audioElements: {}, // Store audio elements for each track
            currentTimes: {},
            progressInterval: null,
            audioError: null,
            totalBars: 30
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace("/");
        }
        this.$root.updateParent("Lajna Audios");
        this.getEvents();
    },
    methods: {
        getEvents() {
            this.loading = true;
            let data = {
                type: 'audio'
            };
            UserService.authPostRequest(`get-lajna-content-list`, data).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.audioItems = response.data.data.list;
                        this.permissions = response.data.data.permissions;
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        formatTime(seconds) {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = Math.floor(seconds % 60);
            return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
        },

        getCurrentBarIndex(audioId) {
            const audio = this.audioElements[audioId];
            if (!audio || this.currentlyPlaying !== audioId) return -1;
            const currentProgress = (this.currentTimes[audioId] || 0) / audio.duration;
            return Math.floor(currentProgress * this.totalBars);
        },

        isBarCompleted(audioId, barIndex) {
            return barIndex <= this.getCurrentBarIndex(audioId);
        },

        isCurrentBar(audioId, barIndex) {
            return barIndex === this.getCurrentBarIndex(audioId) + 1;
        },

        getCurrentBarProgress(audioId, barIndex) {
            const audio = this.audioElements[audioId];
            if (!audio || this.currentlyPlaying !== audioId) return 0;

            const currentProgress = (this.currentTimes[audioId] || 0) / audio.duration;
            const progressPerBar = 1 / this.totalBars;
            const currentBarIndex = Math.floor(currentProgress * this.totalBars);

            if (barIndex - 1 !== currentBarIndex) return 0;

            const barStartProgress = currentBarIndex * progressPerBar;
            const progressWithinBar = (currentProgress - barStartProgress) / progressPerBar;
            return Math.min(progressWithinBar * 100, 100);
        },

        seekAudio(event, audioId) {
            const audio = this.audioElements[audioId];
            if (!audio || this.currentlyPlaying !== audioId) return;

            const waveform = event.currentTarget;
            const rect = waveform.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const percentage = x / rect.width;

            audio.currentTime = audio.duration * percentage;
        },

        async handlePlay(audioId) {
            const audioItem = this.audioItems.find(item => item.id === audioId);

            // If the same audio is playing, pause it
            if (this.currentlyPlaying === audioId) {
                this.audioElements[audioId].pause();
                this.currentlyPlaying = null;
                clearInterval(this.progressInterval);
                return;
            }

            // Pause any currently playing audio
            if (this.currentlyPlaying) {
                this.audioElements[this.currentlyPlaying].pause();
                clearInterval(this.progressInterval);
            }

            try {
                // Initialize audio element if it doesn't exist
                if (!this.audioElements[audioId]) {
                    audioItem.loading = true;
                    audioItem.error = null;
                    this.audioError = null;

                    const audio = new Audio(audioItem.src);

                    await new Promise((resolve, reject) => {
                        audio.addEventListener('loadedmetadata', resolve);
                        audio.addEventListener('error', () => {
                            reject(new Error(`Could not load audio: ${audioItem.title}`));
                        });
                    });

                    audioItem.duration = this.formatTime(audio.duration);
                    this.audioElements[audioId] = audio;

                    // Set up ended event handler
                    audio.onended = () => {
                        this.currentlyPlaying = null;
                        clearInterval(this.progressInterval);
                        this.currentTimes[audioId] = 0;
                    };
                }

                // Play the audio
                await this.audioElements[audioId].play();
                this.currentlyPlaying = audioId;

                // Start progress tracking
                this.progressInterval = setInterval(() => {
                    this.currentTimes = {
                        ...this.currentTimes,
                        [audioId]: this.audioElements[audioId].currentTime
                    };
                }, 50);

            } catch (error) {
                console.error('Audio playback error:', error);
                audioItem.error = 'Failed to play audio';
                this.audioError = `Error playing ${audioItem.title}. Please check if the audio file exists.`;
            } finally {
                audioItem.loading = false;
            }
        }
    },
    beforeUnmount() {
        // Clean up all audio elements
        Object.values(this.audioElements).forEach(audio => {
            audio.pause();
            audio.src = '';
        });
        clearInterval(this.progressInterval);
    },
    components: {
        CategoryFilter,
        EventSlider
    }
}
</script>

<style>
.bottom-button {
    display: block;
    width: 100%;
    margin: 1rem auto;
    padding: 0.75rem 1.5rem;
    background: #0d4f17;
    color: white;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
    text-align: center;
}

.audio-section {
    padding: 0 0rem;
    
}

.error-banner {
    background-color: #fee2e2;
    color: #dc2626;
    padding: 0.75rem;
    border-radius: 0.375rem;
    margin-bottom: 1rem;
    font-size: 0.875rem;
}

.audio-list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.audio-card {
    background: white;
    border-radius: 8px;
    padding: 0.75rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.audio-header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.5rem;
}

.play-btn {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: none;
    background: #0d4f17;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 0.875rem;
    transition: all 0.2s ease;
}

.play-btn:hover:not(:disabled) {
    background: #0a3f12;
    transform: scale(1.05);
}

.play-btn:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.loading-spinner {
    display: inline-block;
    width: 12px;
    height: 12px;
    border: 2px solid #ffffff;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.audio-title {
    font-size: 0.875rem;
    font-weight: 500;
    color: #333;
    flex: 1;
}

.waveform {
    margin: 0.5rem 0;
    padding: 0 0.5rem;
    cursor: pointer;
}

.wave-container {
    height: 40px;
    display: flex;
    align-items: center;
    gap: 2px;
}

.wave-bar {
    flex: 1;
    position: relative;
    overflow: hidden;
    background: #9CB5A0;
    opacity: 0.5;
    height: 100%;
    min-width: 2px;
    transition: background-color 0.3s ease;
}

.wave-bar.completed {
    background-color: #0d4f17;
    opacity: 0.7;
}

.wave-bar.current {
    background-color: #9CB5A0;
    opacity: 0.7;
}

.wave-bar:nth-child(odd) {
    height: 50%;
}

.wave-bar:nth-child(3n) {
    height: 60%;
}

.wave-progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #0d4f17;
    transition: width 0.05s linear;
}

.audio-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    font-size: 0.75rem;
    color: #666;
}

.current-time {
    color: #0d4f17;
    font-weight: 500;
}

.no-audios-message {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 270px;
    background: #f8f9fa;
    border-radius: 8px;
    margin: 1rem 0;
    border: 1px dashed #dee2e6;
}

.message-content {
    text-align: center;
    color: #6c757d;
}

.message-content i {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.message-content h3 {
    font-size: 1.2rem;
    margin: 0;
    font-weight: 500;
}

@media (max-width: 640px) {
    .audio-card {
        padding: 0.5rem;
    }

    .wave-container {
        height: 30px;
    }

    .audio-header {
        margin-bottom: 0.25rem;
    }
}
</style>