<template>
        <div class="section mt-1">
            <!-- Event Slider Section -->
            <div class="slider-wrapper">
                <EventSlider />
            </div>
            <CategoryFilter />

            <hr>

            <!-- Links Section -->
            <div class="links-section">
                <div v-for="(link, index) in links" :key="index" class="link-item">
                    <div class="link-content">
                        <span class="link-text">{{ link.title }}</span>
                        <button class="share-button">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="18" cy="5" r="3" />
                                <circle cx="6" cy="12" r="3" />
                                <circle cx="18" cy="19" r="3" />
                                <line x1="8.59" y1="13.51" x2="15.42" y2="17.49" />
                                <line x1="15.41" y1="6.51" x2="8.59" y2="10.49" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import CategoryFilter from '../../components/CategoryFilter.vue';
import EventSlider from '../../components/EventSlider.vue';

export default {
    name: 'LinksSection',
    data() {
        return {
            links: [
                { title: 'PEACE RADIO', url: '#' },
                { title: 'ZAMEEL', url: '#' },
                { title: 'WISDOM GLOBAL TV', url: '#' },
                { title: 'WHATSAPP GROUS', url: '#' }
            ]
        }
    },
    components: {
        CategoryFilter,
        EventSlider
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace("/");
        }
        this.$root.updateParent("Lajna Links");
    },
}
</script>

<style scoped>
.links-section {
    padding: 0rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 600px;
    margin: 0 auto;
}

.link-item {
    width: 100%;
}

.link-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f1f1f1;
    /* Cream color */
    padding: 0.75rem 1.5rem;
    border-radius: 0.25rem;
    border: 1px solid #e5e5e5;
}

.link-text {
    font-weight: 500;
    color: #000;
    font-size: 1rem;
}

.share-button {
    background-color: #2F4F4F;
    /* Dark slate gray for the share button */
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    padding: 0.5rem;
}

.share-button:hover {
    opacity: 0.9;
}

/* Keeping your existing bottom-button styles */
.bottom-button {
    display: block;
    width: 100%;
    margin: 1rem auto;
    padding: 0.75rem 1.5rem;
    background: #0d4f17;
    color: white;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
    text-align: center;
    text-decoration: none;
}
</style>