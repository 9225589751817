<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-1 page-title">
            <h3 class="text-center mt-1 custom-title">Wisdom Youth Directory</h3>
        </div>
    </div>
    <div class="section pt-0 mt-1 pb-2">
        <div class="card">
            <div class="card-body">
                <Form @submit="handleRegistration" :validation-schema="schema">
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="directory_type">
                                Directory Type
                            </label>
                            <div class="form-check mb-1 col-12" v-for="(item, index) in directory_type_list"
                                v-bind:key="item.id" :set="(id = 'DirectoryType' + (index + 1))">
                                <Field :disabled="disable_all" name="directory_type" type="checkbox" :value="item.id"
                                    class="form-check-input" v-bind:id="id" v-model="formData.directory_type" />
                                <label class="form-check-label" v-bind:for="id">{{
                                    item.name_mal
                                }}</label>
                            </div>
                            <ErrorMessage name="directory_type" class="error-feedback" />
                        </div>
                    </div>

                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="name">
                                Name
                                <span class="text-danger">*</span>
                            </label>
                            <Field type="text" :disabled="disable_all" class="form-control" name="name"
                                v-model="formData.name" placeholder="Enter Name" autocomplete="off" />
                            <ErrorMessage name="name" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="mobile">
                                Mobile
                                <span class="text-danger">*</span>
                            </label>
                            <Field type="number" :disabled="disable_all" class="form-control" name="mobile"
                                v-model="formData.mobile" placeholder="Enter Mobile" autocomplete="off" />
                            <ErrorMessage name="mobile" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="whatsapp">
                                Whatsapp
                            </label>
                            <Field type="number" :disabled="disable_all" class="form-control" name="whatsapp"
                                v-model="formData.whatsapp" placeholder="Enter Whatsapp" autocomplete="off" />
                            <ErrorMessage name="whatsapp" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="age">
                                Age
                                <span class="text-danger">*</span>
                            </label>
                            <Field type="number" :disabled="disable_all" class="form-control" name="age"
                                v-model="formData.age" placeholder="Enter Age" autocomplete="off" />
                            <ErrorMessage name="age" class="error-feedback" />
                        </div>
                    </div>
                    <!-- Conditional District Selection -->
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="district_id">
                                <span>District</span>
                            </label>
                            <Field as="select" class="form-control custom-select" v-model="formData.district_id"
                                name="district_id" @change="handleDistrictChange">
                                <option value="">Select District</option>
                                <option v-for="dist in district_list" :key="dist.id" :value="dist.id">
                                    {{ dist.name }}
                                </option>
                            </Field>
                            <ErrorMessage name="district_id" class="error-feedback" />
                        </div>
                    </div>

                    <!-- Conditional Zone Selection -->
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="zone_id">
                                <span>Zone</span>
                            </label>
                            <Field as="select" class="form-control custom-select" v-model="formData.zone_id" name="zone_id"
                                @change="handleZoneChange">
                                <option value="">Select Zone</option>
                                <option v-for="zn in zone_list" :key="zn.id" :value="zn.id">
                                    {{ zn.name }}
                                </option>
                            </Field>
                            <ErrorMessage name="zone_id" class="error-feedback" />
                        </div>
                    </div>

                    <!-- Conditional Unit Selection -->
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="unit_id">
                                <span>Unit</span>
                            </label>
                            <Field as="select" class="form-control custom-select" v-model="formData.unit_id"
                                name="unit_id">
                                <option value="">Select Unit</option>
                                <option v-for="unt in unit_list" :key="unt.id" :value="unt.id">
                                    {{ unt.name }}
                                </option>
                            </Field>
                            <ErrorMessage name="unit_id" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="native_place">
                                Native Place
                            </label>
                            <Field type="text" :disabled="disable_all" class="form-control" name="native_place"
                                v-model="formData.native_place" placeholder="Enter Native Place" autocomplete="off" />
                            <ErrorMessage name="native_place" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="work_place">
                                Work Place
                            </label>
                            <Field type="text" :disabled="disable_all" class="form-control" name="work_place"
                                v-model="formData.work_place" placeholder="Enter Work Space" autocomplete="off" />
                            <ErrorMessage name="work_place" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="profession">
                                Profession/Job
                            </label>
                            <div class="form-check mb-1 col-12" v-for="(item, index) in profession_list"
                                v-bind:key="item.id" :set="(id = 'profession' + (index + 1))">
                                <Field :disabled="disable_all" name="profession" type="checkbox" :value="item.id"
                                    class="form-check-input" v-bind:id="id" v-model="formData.profession" />
                                <label class="form-check-label" v-bind:for="id">{{
                                    item.name_mal
                                }}</label>
                            </div>
                            <ErrorMessage name="profession" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="adarsham">
                                Adarsham Category
                            </label>
                            <div class="form-check mb-1 col-12" v-for="(item, index) in adarsham_list"
                                v-bind:key="item.id" :set="(id = 'adarsham' + (index + 1))">
                                <Field :disabled="disable_all" name="adarsham" type="checkbox" :value="item.id"
                                    class="form-check-input" v-bind:id="id" v-model="formData.adarsham" />
                                <label class="form-check-label" v-bind:for="id">{{
                                    item.name_mal
                                }}</label>
                            </div>
                            <ErrorMessage name="adarsham" class="error-feedback" />
                        </div>
                    </div>
                    <!-- <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="skilset">
                                                    Skillset
                                                </label>
                                    <div class="form-check mb-1 col-12" v-for="(item, index) in skilset_list" v-bind:key="item.id" :set="(id = 'skilset' + (index + 1))">
                                        <Field :disabled="disable_all" name="skilset" type="checkbox" :value="item.id" class="form-check-input" v-bind:id="id" v-model="formData.skilset" />
                                        <label class="form-check-label" v-bind:for="id">{{
                                                            item.name_mal
                                                        }}</label>
                                    </div>
                                    <ErrorMessage name="skilset" class="error-feedback" />
                                </div>
                            </div> -->
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="qualification">
                                Qualification
                            </label>
                            <div class="form-check mb-1 col-12" v-for="(item, index) in qualification_list"
                                v-bind:key="item.id" :set="(id = 'qualification' + (index + 1))">
                                <Field :disabled="disable_all" name="qualification" type="checkbox" :value="item.id"
                                    class="form-check-input" v-bind:id="id" v-model="formData.qualification" />
                                <label class="form-check-label" v-bind:for="id">{{
                                    item.name_mal
                                }}</label>
                            </div>
                            <ErrorMessage name="qualification" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="wisdom_programs">
                                Our Programs attended before
                            </label>
                            <div class="form-check mb-1 col-12" v-for="(item, index) in wisdom_programs_list"
                                v-bind:key="item.id" :set="(id = 'wisdom_programs' + (index + 1))">
                                <Field :disabled="disable_all" name="wisdom_programs" type="checkbox" :value="item.id"
                                    class="form-check-input" v-bind:id="id" v-model="formData.wisdom_programs" />
                                <label class="form-check-label" v-bind:for="id">{{
                                    item.name_mal
                                }}</label>
                            </div>
                            <ErrorMessage name="wisdom_programs" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="kids_under_14">
                                No of kids under 14
                            </label>
                            <Field type="number" :disabled="disable_all" class="form-control" name="kids_under_14"
                                v-model="formData.kids_under_14" placeholder="Enter No of Kids under 14"
                                autocomplete="off" />
                            <ErrorMessage name="kids_under_14" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="remarks">
                                Disability Percentage(%)
                            </label>
                            <Field type="number" :disabled="disable_all" class="form-control"
                                name="disability_percentage" v-model="formData.disability_percentage"
                                placeholder="Enter % of Disability" autocomplete="off" />
                            <ErrorMessage name="remarks" class="error-feedback" />
                        </div>
                    </div>

                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="remarks">
                                Remarks
                            </label>
                            <Field type="text" :disabled="disable_all" class="form-control" name="remarks"
                                v-model="formData.remarks" placeholder="Enter Remarks" autocomplete="off" />
                            <ErrorMessage name="remarks" class="error-feedback" />
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading"
                        v-if="!this.$route.params.id">
                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                        <span>Save</span>
                    </button>
                </Form>
            </div>
        </div>
    </div>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
import {
    Form,
    Field,
    ErrorMessage
} from "vee-validate";
import * as yup from "yup";
import UserService from "../../services/user.service";
import $ from "jquery";
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
export default {
    name: "Admin List",
    props: {
        unit_id: null,
        permissions: Object,
    },
    components: {
        Form,
        Field,
        ErrorMessage,
        AlertModel,
        DialogModel,
    },
    data() {
        const schema = yup.object().shape({
            name: yup.string().required("ഫിൽ ചെയ്യുക!"),
            mobile: yup.string().required("ഫിൽ ചെയ്യുക!"),
            age: yup.string().required("ഫിൽ ചെയ്യുക!"),
        });
        return {
            loading: false,
            schema,
            alert_type: "",
            alert_message: "",
            dialog_message: "",
            dialog_action: "",
            directory_type_list: [],
            profession_list: [],
            skilset_list: [],
            adarsham_list: [],
            qualification_list: [],
            wisdom_programs_list: [],
            disable_all: false,
            district_list: [],
            zone_list: [],
            unit_list: [],
            formData: {
                directory_type: [],
                wisdom_programs: [],
                profession: [],
                skilset: [],
                adarsham: [],
                qualification: [],
                name: '',
                mobile: '',
                whatsapp: '',
                age: '',
                remarks: '',
                work_place: '',
                native_place: '',
                kids_under_14: '',
                district_id: '',
                zone_id: '',
                unit_id: '',
                disability_percentage: ''
            },
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        childLength() {
            return parseInt(this.formData.lessthan_16_count);
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace("/");
        } else {
            this.$root.updateParent("Youth Directory");
            this.getComboboxValues('YOUTH_DIRECTORY_TYPE');
            this.getComboboxValues('YOUTH_DIRECTORY_PROFESSION');
            this.getComboboxValues('YOUTH_DIRECTORY_QUALIFICATION');
            this.getComboboxValues('YOUTH_DIRECTORY_SKILSET');
            this.getComboboxValues('YOUTH_DIRECTORY_ADARSHAM');
            this.getComboboxValues('YOUTH_DIRECTORY_WISDOM_PROGRAMS');
            this.getDistrictList();
            this.getZoneList(this.currentUser.district_id);
            this.getUnitList(this.currentUser.unit_id);
        }
        if (this.$route.params.id) {
            this.getData();
            this.disable_all = true;
        }

        window.onpopstate = function () {
            if ($("body").hasClass("modal-open")) {
                location.reload();
            }
        };
    },
    methods: {
        getDistrictList() {
            // this.loading_list = true;
            let data = {}
            UserService.authPostRequest(`get-district-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.district_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getZoneList(district_id) {
            // this.loading_list = true;
            let data = {
                district_id: district_id
            }
            UserService.authPostRequest(`get-zone-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.zone_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getUnitList(zone_id) {
            // this.loading_list = true;
            let data = {
                zone_id: zone_id
            }
            UserService.authPostRequest(`get-unit-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.unit_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        handleDistrictChange() {
            this.zone_list = [];
            this.unit_list = [];
            if (parseInt(this.formData.district_id)) {
                this.getZoneList(this.formData.district_id);
            }
        },

        handleZoneChange() {
            this.formData.unit = "";
            if (parseInt(this.formData.zone_id)) {
                this.getUnitList(this.formData.zone_id);
            }
        },
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id,
            };
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case "YOUTH_DIRECTORY_TYPE":
                            this.directory_type_list = response.data.data.list.options;
                            break;
                        case "YOUTH_DIRECTORY_PROFESSION":
                            this.profession_list = response.data.data.list.options;
                            break;
                        case "YOUTH_DIRECTORY_QUALIFICATION":
                            this.qualification_list = response.data.data.list.options;
                            break;
                        case "YOUTH_DIRECTORY_SKILSET":
                            this.skilset_list = response.data.data.list.options;
                            break;
                        case "YOUTH_DIRECTORY_ADARSHAM":
                            this.adarsham_list = response.data.data.list.options;
                            break;
                        case "YOUTH_DIRECTORY_WISDOM_PROGRAMS":
                            this.wisdom_programs_list = response.data.data.list.options;
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        handleRegistration(data) {
            if (data.directory_type.length == 0) {
                this.alert_type = "Warning";
                this.alert_message = 'Directory Type സെലക്ട് ചെയ്യുക';
                this.$refs.alert_model.openModel();
                return false;
            }
            this.loading = true;

            UserService.authPostRequest("store-directory", data).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.alert_type = "Success";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                        this.formData = {
                            directory_type: [],
                            wisdom_programs: [],
                            profession: [],
                            skilset: [],
                            adarsham: [],
                            qualification: [],
                            name: '',
                            mobile: '',
                            whatsapp: '',
                            age: '',
                            remarks: '',
                            work_place: '',
                            native_place: '',
                            kids_under_14: '',
                            district_id: '',
                            zone_id: '',
                            unit_id: '',
                            disability_percentage: ''
                        };
                    } else if (response.data.status == "warning") {
                        this.alert_type = "Warning";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = "Error";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getData() {
            this.loading = true;
            let data = {
                id: this.$route.params.id,
            }
            UserService.authPostRequest('show-directory', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.loading = false;
                        if(response.data.data.list.district_id){
                            this.getZoneList(response.data.data.list.district_id);
                        }
                        if(response.data.data.list.zone_id){
                            this.getUnitList(response.data.data.list.zone_id);
                        }
                        this.formData = {
                            directory_type: JSON.parse(response.data.data.list.directory_type),
                            wisdom_programs: JSON.parse(response.data.data.list.wisdom_programs),
                            profession: JSON.parse(response.data.data.list.profession),
                            skilset: JSON.parse(response.data.data.list.skilset),
                            adarsham: JSON.parse(response.data.data.list.adarsham),
                            qualification: JSON.parse(response.data.data.list.qualification),
                            name: response.data.data.list.name,
                            mobile: response.data.data.list.mobile,
                            whatsapp: response.data.data.list.whatsapp,
                            age: response.data.data.list.age,
                            remarks: response.data.data.list.remarks,
                            work_place: response.data.data.list.work_place,
                            district_id: response.data.data.list.district_id,
                            zone_id: response.data.data.list.zone_id,
                            unit_id: response.data.data.list.unit_id,
                            native_place: response.data.data.list.native_place,
                            kids_under_14: response.data.data.list.kids_under_14,
                            disability_percentage: response.data.data.list.disability_percentage
                        }
                    } else {
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.alert_type = 'Error';
                    this.alert_message = error.data.message;
                    this.$refs.alert_model.openModel();
                }
            );
        },
    },
};
</script>

<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.green-tick {
    font-size: 18px;
    color: darkgreen;
}

.red-cross {
    font-size: 18px;
    color: red;
}

.qhls-text {
    font-style: italic;
    color: #266bd1;
}

.custom-file-upload {
    position: relative;
    display: flex;
    width: 50%;
    height: 100px;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    opacity: 1.5;
}
</style>
